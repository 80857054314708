import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import HeroChevron from "../../components/hero/hero-chevron";
import List from "../../components/custom-widgets/list";
import FAQAccordion from "../../components/faq/faq-accordion";
import VimeoVideoFrame from "../../components/custom-widgets/vimeo-video-frame";

import styles from "./greenlight-kids-debit-card.module.scss";

// Helpers
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const Greenlight = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/personal-banking/greenlight/hero-greenlight-040323-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/greenlight/hero-greenlight-040323-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/greenlight/hero-greenlight-040323-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/greenlight/hero-greenlight-040323-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/greenlight/hero-greenlight-040323-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/greenlight/hero-greenlight-040323-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/personal-banking/greenlight/hero-greenlight-040323-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Greenlight"
    }
  ];

  const seoData = {
    title: "Greenlight Kids Debit Card & Banking App",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Greenlight Kids Debit Card & Banking App"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Help your kids become money-savvy with WaFd Bank's Greenlight debit card and allowance app. Teach them about smart saving and spending with parental controls."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/greenlight-kids-debit-card"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-greenlight-040323.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "greenlight-page-hero",
    ...getHeroImgVariables(imgData),
    altText: "Mother and daughter smiling while looking at the green light app and credit card.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Get Greenlight for your kids, courtesy of WaFd Bank."
          }
        },
        {
          id: 2,
          button: {
            id: "greenlight-hero-cta",
            text: "Get started today",
            url: "https://greenlight.com/wafd",
            externalLink: true,
            target: "_blank",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const greenlightOffers = {
    greenCircleCheckmark: true,
    accountName: "greenlight-offers",
    items: [
      {
        id: 1,
        text: "Kid's debit card (which parents can turn off if needed)"
      },
      {
        id: 2,
        text: "Flexible parental controls to set store-level spending limits"
      },
      {
        id: 3,
        text: "Option to receive alerts whenever the card is used"
      },
      {
        id: 4,
        text: "Ability to automate allowances"
      },
      {
        id: 5,
        text: "Assign and manage chores"
      },
      {
        id: 6,
        text: "Fun games to help kids learn money skills"
      },
      {
        id: 7,
        text: "Teach smart spending"
      },
      {
        id: 8,
        text: "Easy to use tools for saving, spending, earning, and giving goals"
      },
      {
        id: 9,
        text: "And more!",
        class: "mb-0"
      }
    ]
  };

  const faqAccordionData = {
    title: "Greenlight FAQs",
    sectionClass: "py-0",
    id: "greenlight-faq-accordion",
    faqCategoryNames: ["Greenlight"]
  };

  const greenlightVideoData = {
    vimeoId: "948935624",
    title: "Greenlight Complimentary for WaFd Bank clients",
    class: "iframe w-100 border-radius-12 mb-3"
  };

  const greenlightVideo2Data = {
    vimeoId: "1029423244",
    title: "Holiday Saving with Greenlight",
    class: "iframe w-100 border-radius-12 mb-3"
  };

  return (
    <SecondaryLanding>
      <HeroChevron {...heroChevronData} />
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Set your kids up for financial success with Greenlight</h1>
        <div className="row">
          <div className="col-lg-6">
            <h4>
              Greenlight empowers parents to raise financially smart kids. You'll get free access to the Greenlight app
              with any WaFd checking account&mdash;you and up to five kids can download the same Greenlight app, but you
              both have different experiences. Kids learn how to manage their own money while staying safe and connected
              to family, and parents can send funds, set flexible controls, and more.
            </h4>
          </div>
          <div className="col-lg-6">
            <VimeoVideoFrame {...greenlightVideoData} />
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <VimeoVideoFrame {...greenlightVideo2Data} />
            </div>
            <div className="col-lg-6">
              <h2 className="text-success font-weight-semi-bold">Holiday Saving with Greenlight</h2>
              <p>
                Set your kids up for success this holiday season with the Greenlight App through WaFd Bank. Teach your
                kids about money with the Greenlight App for Financial Freedom. The Greenlight App let's parents assign
                chores, manage allowance, set spending limits, and helps kids learn to budget right from their phone.{" "}
                <a
                  href="https://greenlight.com/wafd?partner=WAFD&promotionId=gl4b_wafd&plan=select"
                  id="greenlight-sign-up-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign up for Greenlight
                </a>{" "}
                at no cost for WaFd Bank customers.
              </p>
              <a
                href="https://greenlight.com/wafd?partner=WAFD&promotionId=gl4b_wafd&plan=select"
                id="greenlight-sign-up-btn"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary w-100 w-sm-auto"
              >
                Sign Up for Greenlight
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="container pb-0">
        <div className="row">
          <div className="col-lg-6">
            <h3 className="font-weight-bold text-success">Greenlight comes with:</h3>
            <List {...greenlightOffers} />
          </div>
          <div className={`col-lg-6 text-white border-radius-12 px-3 pt-3 h-100 ${styles.greenGradient}`}>
            <h3 className="font-weight-bold">Get started today in four easy steps:</h3>
            <ol className="font-weight-bold">
              <li>
                Register at{" "}
                <a
                  className="text-white"
                  id="greenlight-registration-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://greenlight.com/wafd"
                >
                  greenlight.com/wafd
                </a>
              </li>
              <li>Enter your phone number and connect your WaFd Checking account</li>
              <li>Download the Greenlight app</li>
              <li>Begin teaching your kids healthy financial habits</li>
            </ol>
            <div className="text-center">
              <a
                id="greenlight-registration-btn"
                href="https://greenlight.com//wafd"
                className="btn btn-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get started today
              </a>
              <div className="mt-4">
                <img
                  className="w-100"
                  style={{ maxWidth: "410px" }}
                  src="../../images/greenlight-mobile-screens.png"
                  alt="Greenlight mobile screens."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <FAQAccordion {...faqAccordionData} />

        <p className="mt-4 mb-0 text-sm text-gray-60" id="greenlight-description">
          * WaFd Bank customers are eligible for the Greenlight SELECT plan at no cost when they connect their WaFd Bank
          account as the Greenlight funding source for the entirety of the promotion. Subject to minimum balance
          requirements and identity verification. Upgrades will result in additional fees. Upon termination of
          promotion, customers will be responsible for associated monthly fees. See terms for details. Offer ends
          02/03/2025. Offer subject to change and partner participation.
        </p>
      </section>
    </SecondaryLanding>
  );
};
export default Greenlight;
